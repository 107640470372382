import React from 'react';
import PropTypes from 'prop-types';
import WithSmartEdit from 'cms/WithSmartEdit';
import Consultation from 'pages/Consultation';

const CmsTimendoComponent = ({ areaId, storeId }) => {
  // If just the areaId is set, it is used as areaId
  if (areaId && !storeId) {
    return <Consultation areaId={areaId} hasMetaTags headlineSeo="h3" />;
  }
  // If both ids are set, areaId is actually the interventionId
  if (areaId && storeId) {
    return <Consultation interventionId={areaId} groupId={storeId} hasMetaTags headlineSeo="h3" />;
  }

  return <Consultation hasMetaTags headlineSeo="h3" />;
};

CmsTimendoComponent.propTypes = {
  /** The id of area */
  areaId: PropTypes.string,
  /** The id of store */
  storeId: PropTypes.string,
};

CmsTimendoComponent.defaultProps = {
  areaId: null,
  storeId: null,
};

export default WithSmartEdit(CmsTimendoComponent);
